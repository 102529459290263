import React from 'react';

import Box from '../../../components/Box';
import Flex from '../../../components/Flex';
import Text from '../../../components/Text';
import BackgroundImage from '../../../components/BackgroundImage';
import { Media, responsive } from '../../../contexts/responsive';

import roof from './roof.svg';
import store from './store.svg';
import clean from './clean.svg';
import chef from './chef.svg';
import store_desk from './store-desk.svg';
import clean_desk from './clean-desk.svg';
import chef_desk from './chef-desk.svg';
import like from './like.svg'

const DescBlock = ({ title, desc, ...props }) => (
  <Box flex={1} {...props}>
    <Text.Bold fontSize={responsive('1.25em', '2.75em')}>{title}</Text.Bold>
    <Text color="custom.textGray" lineHeight={responsive(1.5, 1.67)} fontSize={responsive('0.875em', '1.875em')}>{desc}</Text>
  </Box>
)

const Like = () => {
  return (
    <Box pt={responsive('4.75em', '9.75em')}>
      <Box>
        <Box width={responsive('14.75em', '32.625em')}><BackgroundImage src={like} ratio={522.65 / 92.32} /></Box>
        <Text.Bold fontSize={responsive('0.875em', '1.875em')} mt={responsive('0.875rem', '1.75em')}>
          透過創意和設計，<br/>
          將「內容」創造成有共鳴的「溝通情境與體驗」<br/>
          From Content to Context
        </Text.Bold>
      </Box>
      <Box pt={responsive('2em', '3.5em')}>
        <Box mx={responsive('-0.75em', '-1.875em')}>
          <BackgroundImage src={roof} ratio={718.75 / 152.65} />
        </Box>
        <Box borderLeft={responsive('1.5px solid black', '3px solid black')} borderRight={responsive('1.5px solid black', '3px solid black')}>
          <Flex mt="-0.5em" pl={responsive('1.0625em', '5.625em')} pr={responsive('0.625em', '3.125em')} pt={responsive('1.5em', '4em')}>
            <DescBlock mt={responsive(0, '0.25em')} mr={responsive('1.125em', '4.125em')} title="選品雜貨店" desc="有人說，我們像選品雜貨店，專門將資料庫打造成有趣的選品，讓更多人願意來逛逛" />
            <Box width={responsive('8.75em', '33.125em')}>
              <Media lessThan='lg'>
                <BackgroundImage ratio={319 / 378.73} src={store} />
              </Media>
              <Media greaterThanOrEqual='lg'>
                <BackgroundImage ratio={530 / 305} src={store_desk} />
              </Media>
            </Box>
          </Flex>
          <Box mt="-1.5px" py={responsive('3px', '7.5px')} borderTop={responsive('1.5px solid black', '3px solid black')} borderBottom={responsive('1.5px solid black', '3px solid black')} />
          <Flex mt="-0.5em" pl={responsive('0.25em', '3.125em')} pr={responsive('1.25em', '5.5em')} pt={responsive('1.5625em', '3.275em')}>
            <Box width={responsive('8.75em', '32.25em')}>
              <Media lessThan='lg'>
                <BackgroundImage ratio={331.17 / 412.18} src={clean} />
              </Media>
              <Media greaterThanOrEqual='lg'>
                <BackgroundImage ratio={516 / 372} src={clean_desk} />
              </Media>
            </Box>
            <DescBlock mt={responsive(0, '1.875em')} ml={responsive('1.125em', '5.25em')} title="整理職人" desc="有人說，我們像是整理職人，幫許多企業梳理雜亂困難的資訊，再變成故事分享給消費者" />
          </Flex>
          <Box py={responsive('3px', '7.5px')} borderTop={responsive('1.5px solid black', '3px solid black')} borderBottom={responsive('1.5px solid black', '3px solid black')} />
          <Flex mt="-0.5em" pl={responsive('1.125em', '5.625em')} pr={responsive('1.125em', '4.125em')} pt={responsive('1.375em', '4.5em')}>
            <DescBlock mt={responsive(0, '2.625em')} mr={responsive('1.375em', '4.125em')} title="創意廚師" desc="也有人說，我們是資訊界的無菜單創意料理廚師，特別愛挑戰難消化的食材" />
            <Box width={responsive('8.5em', '32.5625em')}>
              <Media lessThan='lg'>
                <BackgroundImage ratio={304.04 / 383} src={chef} />
              </Media>
              <Media greaterThanOrEqual='lg'>
                <BackgroundImage ratio={521 / 364} src={chef_desk} />
              </Media>
            </Box>
          </Flex>
          <Box py={responsive('3px', '7px')} bg="black" />
        </Box>
        <Box height={'1px'} bg="custom.borderGray" mt={responsive('2.5em', '7.375em')} mb={responsive('2.5em', '5.75em')} />
      </Box>
    </Box>
  )
};

export default Like;
