import React from 'react'
import { Container } from '@chakra-ui/react'
import { responsive } from '../contexts/responsive';

const ReContainer = (props) => {
  return (
    <Container maxWidth={responsive('34em', '76.625em')} px="2em" py="0" {...props} />
  )
}

export default ReContainer
